import React from 'react'

import logo from '../images/ombud.png'

const Header = ({ siteTitle }) => (
  <div
    style={{
      background: '#4dd0e1',
      marginBottom: '1.45em',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '1.45em 1.0875em',
      }}
    >
      <img src={logo} style={{ height: '1.5em', margin: 0 }} alt="Ombud" />
    </div>
  </div>
)

export default Header
